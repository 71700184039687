import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, useTheme, useMediaQuery } from '@mui/material';
import { 
  Brightness4, 
  Brightness7,
  BoltRounded,
  WbSunnyRounded 
} from '@mui/icons-material';
import { getGreeting } from '../../theme';

const ChatHeader = ({ darkMode, toggleDarkMode }) => {
  const [greeting, setGreeting] = React.useState(getGreeting());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <AppBar 
      position="static" 
      elevation={0}
      sx={{ 
        background: (theme) => theme.palette.primary.gradient,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography 
            variant={isMobile ? "subtitle1" : "h6"}
            color="white" 
            className="header-title"
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: isMobile ? 0.5 : 1
            }}
          >
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              Shakti 
              <BoltRounded sx={{ 
                fontSize: isMobile ? 20 : 24,
                color: 'yellow',
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { opacity: 0.6 },
                  '50%': { opacity: 1 },
                  '100%': { opacity: 0.6 }
                }
              }} />
            </Box>
            |
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              Astra
              <WbSunnyRounded sx={{ 
                fontSize: isMobile ? 20 : 24,
                color: 'orange',
                animation: 'glow 3s infinite',
                '@keyframes glow': {
                  '0%': { filter: 'brightness(1)' },
                  '50%': { filter: 'brightness(1.3)' },
                  '100%': { filter: 'brightness(1)' }
                }
              }} />
            </Box>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobile ? 1 : 2 }}>
          <Typography 
            variant={isMobile ? "body2" : "subtitle1"}
            color="white"
            className="header-greeting"
            sx={{
              fontSize: isMobile ? '0.875rem' : 'inherit'
            }}
          >
            {greeting}
          </Typography>
          <IconButton 
            onClick={toggleDarkMode} 
            color="inherit"
            size={isMobile ? "small" : "medium"}
            sx={{ 
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.2)'
              }
            }}
          >
            {darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ChatHeader;