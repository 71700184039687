//chatcontainer/js
import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  CircularProgress, 
  Typography,
  Paper,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { MessageGroup } from './MessageComponents';
import { API_CONFIG } from '../../config/api';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import  BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';

const EmptyState = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const suggestions = [
    "What are the key differences between machine learning and deep learning?",
    "Can you help me debug this Python code?",
    "Write a creative story about a time traveler.",
    "Explain quantum computing in simple terms."
  ];

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        gap: 4
      }}
    >
      {/* Welcome Message */}
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <ChatOutlinedIcon 
          sx={{ 
            fontSize: isMobile ? 48 : 64, 
            color: 'primary.main',
            mb: 2,
            opacity: 0.8
          }} 
        />
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            fontWeight: 'bold',
            mb: 2,
            background: 'linear-gradient(45deg, #3B82F6, #10B981)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Start a Conversation
        </Typography>
        <Typography color="text.secondary" sx={{ mb: 4 }}>
          Responses from two advanced chatbots side by side
        </Typography>
      </Box>

      {/* Model Comparison */}
      <Grid container spacing={3} sx={{ maxWidth: 'md', mb: 4 }}>
        <Grid item xs={12} sm={5}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              height: '100%',
              background: theme.palette.mode === 'dark' 
                ? 'rgba(59, 130, 246, 0.1)'
                : 'rgba(59, 130, 246, 0.05)',
              border: '1px solid',
              borderColor: 'primary.main',
              borderRadius: 2,
              textAlign: 'center'
            }}
          >
            <BoltRoundedIcon 
              sx={{ color: 'primary.main', mb: 1 }} 
            />
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Shakti
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Analytical thinking and detailed explanations
            </Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={2} sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <CompareArrowsIcon 
            sx={{ 
              transform: isMobile ? 'rotate(90deg)' : 'none',
              color: 'text.secondary',
              opacity: 0.5
            }} 
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              height: '100%',
              background: theme.palette.mode === 'dark' 
                ? 'rgba(16, 185, 129, 0.1)'
                : 'rgba(16, 185, 129, 0.05)',
              border: '1px solid',
              borderColor: 'secondary.main',
              borderRadius: 2,
              textAlign: 'center'
            }}
          >
            <WbSunnyRoundedIcon
              sx={{ color: 'secondary.main', mb: 1 }} 
            />
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Astra
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Creative solutions and innovative thinking
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Suggested Prompts */}
      <Box sx={{ maxWidth: 'sm', width: '100%' }}>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 2,
            textAlign: 'center',
            color: 'text.secondary',
            fontWeight: 'medium'
          }}
        >
          Try these example prompts:
        </Typography>
        <Grid container spacing={2}>
          {suggestions.map((suggestion, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  background: theme.palette.mode === 'dark' 
                    ? 'rgba(255,255,255,0.05)'
                    : 'rgba(255,255,255,0.9)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: theme.shadows[4]
                  }
                }}
              >
                <Typography variant="body2">
                  {suggestion}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

const ChatContainer = ({ messages, loading, setMessages, chatEndRef }) => {
  const handleShare = async (text, modelName) => {
    try {
      await navigator.clipboard.writeText(`${modelName}'s response:\n\n${text}`);
      return {
        open: true,
        message: 'Response copied to clipboard!',
        severity: 'success'
      };
    } catch (err) {
      return {
        open: true,
        message: 'Failed to copy response',
        severity: 'error'
      };
    }
  };

  const handleShareConversation = async (messageGroup) => {
    try {
      const conversationText = `User: ${messageGroup.user.text}\n\n` +
        `${API_CONFIG.MODEL1.NAME}: ${messageGroup.model1?.text || ''}\n\n` +
        `${API_CONFIG.MODEL2.NAME}: ${messageGroup.model2?.text || ''}`;
      
      await navigator.clipboard.writeText(conversationText);
      return {
        open: true,
        message: 'Conversation copied to clipboard!',
        severity: 'success'
      };
    } catch (err) {
      return {
        open: true,
        message: 'Failed to copy conversation',
        severity: 'error'
      };
    }
  };

  return (
    <Box sx={{
      flex: 1,
      overflowY: 'auto',
      p: 2,
      '&::-webkit-scrollbar': {
        width: 6,
        borderRadius: 10
      },
      '&::-webkit-scrollbar-track': {
        background: 'background.default'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#CBD5E1',
        borderRadius: 10
      }
    }}>
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        {messages.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            {messages.map((messageGroup) => (
              <MessageGroup 
                key={messageGroup.id}
                messageGroup={messageGroup}
                API_CONFIG={API_CONFIG}
                onShare={handleShare}
                onShareConversation={handleShareConversation}
              />
            ))}
            {loading && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
        <div ref={chatEndRef} />
      </Container>
    </Box>
  );
};

export default ChatContainer;