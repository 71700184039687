// src/theme/index.js
import { createTheme } from '@mui/material';
import '../styles/fonts.css';

// Add the getGreeting function
export const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good Morning';
  if (hour < 17) return 'Good Afternoon';
  return 'Good Evening';
};

export const getTheme = (mode) => createTheme({
  palette: {
    mode,
    primary: {
      main: '#4DA0B0',
      light: '#3b82f6',
      dark: '#1d4ed8',
      gradient: mode === 'light' 
        ? 'linear-gradient(135deg, #4DA0B0 0%, #D39D38 100%)'
        : 'linear-gradient(135deg, #4DA0B0 0%, #D39D38 100%)'
    },
    secondary: {
      main: '#D39D38',
      light: '#14b8a6',
      dark: '#0f766e',
      gradient: mode === 'light'
        ? 'linear-gradient(135deg, #1D4350 0%, #A43931 100%)'
        : 'linear-gradient(135deg, #1D4350 0%, #A43931 100%)'
    },
    background: {
      default: mode === 'light' ? '#F9FAFB' : 'linear-gradient(135deg, #1D4350 0%, #A43931 100%)',
      paper: mode === 'light' ? '#F2F3F4' : 'linear-gradient(135deg, #1D4350 0%, #A43931 100%)',
      chat: mode === 'light' ? '#F2F3F4' : '#2C3335'
    },
    text: {
      primary: mode === 'light' ? 'linear-gradient(135deg, #14b8a6 0%, #0d9488 100%)' : '#F9FAFB',
      secondary: mode === 'light' ? '#6B7280' : '#D1D5DB'
    }
  },
  typography: {
    fontFamily: '"Inter", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    h6: {
      fontWeight: 600,
      letterSpacing: '0.01em'
    },
    body1: {
      letterSpacing: '0.01em'
    }
  },
  shape: {
    borderRadius: 12
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .header-title': {
            fontFamily: 'Poppins, sans-serif'
          },
          '& .header-greeting': {
            fontFamily: 'Poppins, sans-serif'
          }
        }
      }
    }
  }
});