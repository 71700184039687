import { Client } from '@gradio/client';

export class ChatService {
  static async getModelResponse(message, modelConfig) {
    try {
      const client = await Client.connect(modelConfig.PATH, {
        hf_token: modelConfig.HF_TOKEN
      });
      
      const payload = {
        [modelConfig.PARAM_NAME]: message
      };

      const result = await client.predict(
        modelConfig.ENDPOINT,
        payload
      );
      
      // Extract the text from the array response
      if (Array.isArray(result.data) && result.data.length > 0) {
        return result.data[0];
      }
      
      return 'No response available';
    } catch (error) {
      console.error(`Error with ${modelConfig.NAME}:`, error);
      throw new Error(`Failed to get response from ${modelConfig.NAME}: ${error.message}`);
    }
  }
}