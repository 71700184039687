// src/styles/StyledComponents.js
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';

export const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: theme.palette.background.chat
}));

export const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '6px'
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.chat
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'light' ? '#CBD5E1' : '#4B5563',
    borderRadius: '3px'
  }
}));

export const MessageBubble = styled(Paper)(({ theme, isUser }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '70%',
    wordWrap: 'break-word',
    backgroundColor: isUser ? theme.palette.primary.main : theme.palette.background.paper,
    color: isUser ? '#FFFFFF' : (theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937'),
    borderRadius: '16px',
    boxShadow: isUser ? 
      `0 2px 5px ${theme.palette.primary.main}33` : 
      `0 2px 5px ${theme.palette.mode === 'light' ? '#00000005' : '#00000033'}`,
    border: isUser ? 'none' : `1px solid ${theme.palette.divider}`,
    '& .MuiTypography-caption': {
      color: isUser ? 'rgba(255, 255, 255, 0.8)' : (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)')
    }
  }));
  

export const MessageGroup = styled(Box)(({ isUser }) => ({
  display: 'flex',
  gap: '12px',
  marginBottom: '24px',
  flexDirection: isUser ? 'row-reverse' : 'row',
  '& .MuiAvatar-root': {
    order: isUser ? 1 : 0
  }
}));

export const InputContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#374151' : '#F9FAFB',
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
      '& textarea': {
        color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#1F2937',
      }
    }
  }));