import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { CircleDot, Sparkles, Zap } from 'lucide-react';

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const pulse = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
`;

const sparkle = keyframes`
  0% { transform: scale(0) rotate(0deg); opacity: 0; }
  50% { transform: scale(1.2) rotate(180deg); opacity: 1; }
  100% { transform: scale(0) rotate(360deg); opacity: 0; }
`;

const gradientMove = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const LoadingContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(45deg, #111827 0%, #1F2937 100%)'
    : 'linear-gradient(45deg, #F9FAFB 0%, #F3F4F6 100%)',
  backgroundSize: '400% 400%',
  animation: `${gradientMove} 15s ease infinite`,
  zIndex: 9999,
  padding: theme.spacing(2),
  overflow: 'hidden'
}));

const CompanyBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255,255,255,0.05)'
    : 'rgba(255,255,255,0.9)',
  backdropFilter: 'blur(10px)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 8px 32px rgba(0,0,0,0.3)'
    : '0 8px 32px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 12px 40px rgba(0,0,0,0.4)'
      : '0 12px 40px rgba(0,0,0,0.15)'
  }
}));

const IconWrapper = styled(Box)(({ delay = 0 }) => ({
  position: 'absolute',
  animation: `${float} 3s ease-in-out infinite`,
  animationDelay: `${delay}s`
}));

const SparkleWrapper = styled(Box)(({ delay = 0, x, y }) => ({
  position: 'absolute',
  left: `${x}%`,
  top: `${y}%`,
  animation: `${sparkle} 2s ease-in-out infinite`,
  animationDelay: `${delay}s`
}));

const LoadingScreen = ({ darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const createSparkles = (count) => {
    return Array.from({ length: count }).map((_, i) => (
      <SparkleWrapper 
        key={i}
        delay={i * 0.3}
        x={Math.random() * 100}
        y={Math.random() * 100}
      >
        <Sparkles 
          size={16} 
          color={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} 
          opacity={0.3}
        />
      </SparkleWrapper>
    ));
  };

  return (
    <LoadingContainer>
      {createSparkles(10)}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
        gap: 4,
        alignItems: 'center',
        position: 'relative'
      }}>
        <CompanyBox>
          <IconWrapper sx={{ top: -20, left: -20 }}>
            <CircleDot 
              size={24} 
              color={theme.palette.primary.main}
              style={{ animation: `${pulse} 2s ease-in-out infinite` }}
            />
          </IconWrapper>
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                color: 'primary.main',
                fontSize: isMobile ? '1.5rem' : '2rem',
                mb: 1
              }}
            >
              Shakti
            </Typography>
            <Typography 
              variant="subtitle1"
              sx={{ 
                color: 'text.secondary',
                fontSize: isMobile ? '0.875rem' : '1rem'
              }}
            >
              by Sandlogic
            </Typography>
          </Box>
        </CompanyBox>

        <Box sx={{ 
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress
            variant="determinate"
            value={progress}
            size={isMobile ? 40 : 60}
            thickness={4}
            sx={{
              color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)',
              position: 'relative',
              zIndex: 1
            }}
          />
          <CircularProgress
            variant="determinate"
            value={progress}
            size={isMobile ? 40 : 60}
            thickness={4}
            sx={{
              color: 'secondary.main',
              position: 'absolute',
              left: 0,
              zIndex: 2,
              animation: `${gradientMove} 3s ease infinite`
            }}
          />
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              color: 'text.secondary',
              fontSize: isMobile ? '0.75rem' : '1rem'
            }}
          >
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>

        <CompanyBox>
          <IconWrapper sx={{ top: -20, right: -20, delay: 0.5 }}>
            <Zap 
              size={24} 
              color={theme.palette.secondary.main}
              style={{ animation: `${pulse} 2s ease-in-out infinite` }}
            />
          </IconWrapper>
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                color: 'secondary.main',
                fontSize: isMobile ? '1.5rem' : '2rem',
                mb: 1
              }}
            >
              Astra
            </Typography>
            <Typography 
              variant="subtitle1"
              sx={{ 
                color: 'text.secondary',
                fontSize: isMobile ? '0.875rem' : '1rem'
              }}
            >
              by Vyomastra
            </Typography>
          </Box>
        </CompanyBox>
      </Box>

      <Typography 
        variant="body2" 
        sx={{ 
          mt: 4,
          color: 'text.secondary',
          opacity: 0.8,
          animation: `${pulse} 2s ease-in-out infinite`,
          textAlign: 'center'
        }}
      >
        Initializing AI Models...
      </Typography>
    </LoadingContainer>
  );
};

export default LoadingScreen;