export const API_CONFIG = {
  MODEL1: {
    PATH: "SandLogicTechnologies/Shakti-2.5B",
    HF_TOKEN: "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",
    NAME: "Shakti 2.5B",
    ENDPOINT: "/chat",
    PARAM_NAME: "message"  // Parameter name for MODEL1
  },
  MODEL2: {
    PATH: "Shreyasr452/AstraBot",
    HF_TOKEN: "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",
    NAME: "Astra",
    ENDPOINT: "/predict",
    PARAM_NAME: "user_question"  // Parameter name for MODEL2
  },
  DEFAULT_PARAMS: {
    temperature: 0.6,
  }
};