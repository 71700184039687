import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Grid,
  CircularProgress
} from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const ModelResponse = ({ message, modelName, onShare }) => {
  const responseText = message?.text || 'No response available';
  const isError = message?.error;

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar sx={{ 
            width: 32, 
            height: 32, 
            bgcolor: modelName === 'Shakti 2.5B' ? 'primary.main' : 'secondary.main',
            opacity: isError ? 0.6 : 1
          }}>
            <SmartToyRoundedIcon />
          </Avatar>
          <Typography variant="subtitle2" color="text.secondary">
            {modelName || 'AI Assistant'}
          </Typography>
        </Box>
        {!isError && (
          <Tooltip title="Copy response">
            <IconButton 
              size="small" 
              onClick={() => onShare(responseText, modelName)}
              sx={{ 
                color: 'text.secondary',
                '&:hover': { color: 'primary.main' } 
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box sx={{
        p: 2,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 1,
        border: '1px solid',
        borderColor: isError ? 'error.light' : 'divider',
        opacity: isError ? 0.8 : 1
      }}>
        <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.6 }}>
          {responseText}
        </Typography>
        {message?.timestamp && (
          <Typography variant="caption" sx={{ mt: 1, display: 'block', opacity: 0.8 }}>
            {message.timestamp}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const UserMessage = ({ messageGroup, onShareConversation }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
    <Box sx={{ maxWidth: '70%', display: 'flex', gap: 2 }}>
      <Tooltip title="Share conversation">
        <IconButton 
          size="small"
          onClick={() => onShareConversation(messageGroup)}
          sx={{ 
            alignSelf: 'flex-start',
            color: 'primary.main',
            bgcolor: 'background.paper',
            '&:hover': { bgcolor: 'background.default' }
          }}
        >
          <ShareIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Box sx={{
        p: 2,
        bgcolor: 'primary.main',
        color: 'white',
        borderRadius: 2,
        boxShadow: 1
      }}>
        <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.6 }}>
          {messageGroup?.user?.text || 'No message'}
        </Typography>
        {messageGroup?.user?.timestamp && (
          <Typography variant="caption" sx={{ mt: 1, display: 'block', opacity: 0.8 }}>
            {messageGroup.user.timestamp}
          </Typography>
        )}
      </Box>
      <Avatar sx={{ bgcolor: 'primary.main' }}>
        <PersonRoundedIcon />
      </Avatar>
    </Box>
  </Box>
);

export const MessageGroup = ({ messageGroup, API_CONFIG, onShare, onShareConversation }) => {
  if (!messageGroup) return null;

  const isModel1Loading = !messageGroup.model1;
  const isModel2Loading = !messageGroup.model2;

  return (
    <Box sx={{ mb: 4 }}>
      <UserMessage messageGroup={messageGroup} onShareConversation={onShareConversation} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {isModel1Loading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              p: 2,
              minHeight: '100px',
              alignItems: 'center'
            }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <ModelResponse 
              message={messageGroup.model1} 
              modelName={API_CONFIG.MODEL1.NAME}
              onShare={onShare}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {isModel2Loading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              p: 2,
              minHeight: '100px',
              alignItems: 'center'
            }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <ModelResponse 
              message={messageGroup.model2} 
              modelName={API_CONFIG.MODEL2.NAME}
              onShare={onShare}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};