import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Snackbar, Alert } from '@mui/material';
import { getTheme } from './theme';
import { ChatContainer as StyledContainer } from './styles/StyledComponents';
import ChatHeader from './components/chat/ChatHeader';
import ChatInput from './components/chat/ChatInput';
import ChatContainer from './components/chat/ChatContainer';
import LoadingScreen from './components/LoadingScreen';
import { ChatService } from './services/ChatService';
import { API_CONFIG } from './config/api';

const App = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const chatEndRef = useRef(null);
  const theme = useMemo(() => getTheme(darkMode ? 'dark' : 'light'), [darkMode]);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    const timer = setTimeout(() => setInitialLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleChat = async () => {
    if (!inputMessage.trim()) return;

    const messageGroup = {
      id: Date.now(),
      user: {
        text: inputMessage,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }
    };

    setMessages(prev => [...prev, messageGroup]);
    setInputMessage('');

    // Handle model1 response
    ChatService.getModelResponse(messageGroup.user.text, API_CONFIG.MODEL1)
      .then(response => {
        setMessages(prev => prev.map(msg =>
          msg.id === messageGroup.id
            ? {
                ...msg,
                model1: {
                  text: response,
                  timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }
              }
            : msg
        ));
      })
      .catch(error => {
        console.error('Model 1 error:', error);
        setMessages(prev => prev.map(msg =>
          msg.id === messageGroup.id
            ? {
                ...msg,
                model1: {
                  text: "An error occurred. Please try again.",
                  timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                  error: true
                }
              }
            : msg
        ));
      });

    // Handle model2 response
    ChatService.getModelResponse(messageGroup.user.text, API_CONFIG.MODEL2)
      .then(response => {
        setMessages(prev => prev.map(msg =>
          msg.id === messageGroup.id
            ? {
                ...msg,
                model2: {
                  text: response,
                  timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }
              }
            : msg
        ));
      })
      .catch(error => {
        console.error('Model 2 error:', error);
        setMessages(prev => prev.map(msg =>
          msg.id === messageGroup.id
            ? {
                ...msg,
                model2: {
                  text: "An error occurred. Please try again.",
                  timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                  error: true
                }
              }
            : msg
        ));
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChat();
    }
  };

  if (initialLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoadingScreen darkMode={darkMode} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledContainer>
        <ChatHeader 
          darkMode={darkMode} 
          toggleDarkMode={() => setDarkMode(prev => !prev)} 
        />
        
        <ChatContainer 
          messages={messages}
          setMessages={setMessages}
          chatEndRef={chatEndRef}
          API_CONFIG={API_CONFIG}
        />

        <ChatInput 
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleChat={handleChat}
          loading={false}
          handleKeyDown={handleKeyDown}
        />

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={3000} 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default App;