// src/components/chat/ChatInput.jsx
import React from 'react';
import { Box, TextField, IconButton, Container, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { InputContainer } from '../../styles/StyledComponents';

const ChatInput = ({ inputMessage, setInputMessage, handleChat, loading, handleKeyDown }) => {
  return (
    <InputContainer elevation={0}>
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            placeholder="Type your message here... (Press Enter to send)"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
                '&:hover fieldset': {
                  borderColor: 'primary.light',
                },
              },
              '& .MuiInputBase-input': {
                '&::placeholder': {
                  opacity: 0.7,
                },
              },
            }}
          />
          <IconButton 
            color="primary" 
            onClick={handleChat} 
            disabled={loading || !inputMessage.trim()}
            sx={{ 
              alignSelf: 'flex-end',
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              '&.Mui-disabled': {
                bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                color: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)'
              }
            }}
          >
            {loading ? 
              <CircularProgress size={24} sx={{ color: 'white' }} /> : 
              <SendIcon />
            }
          </IconButton>
        </Box>
      </Container>
    </InputContainer>
  );
};

export default ChatInput;